<template>
  <v-card flat class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn small @click="resetForm()">
        <v-icon small>mdi-restart</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <create-featured-video-form
        ref="featuredVideoForm"
        :key="componentKey"
        :form="form.featuredVideo"
        :itemId="itemId"
      >
      </create-featured-video-form>
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="bg-secondary text-color-white"
        @click="submit()"
        :loading="loading"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import moment from "moment";

const dailyVideosRepository = RepositoryFactory.get("daily-video");

const CreateFeaturedVideoForm = () =>
  import("../forms/CreateFeaturedVideoForm.vue");

export default {
  components: {
    CreateFeaturedVideoForm
  },
  data: () => ({
    componentKey: 0,
    title: "Add Scheduled Video",
    loading: false,
    itemId: null,
    form: {},
    eventName: "reloadItems_featuredVideos"
  }),
  methods: {
    showDetails(data) {
      /**
       * Uncomment time related variables when time picker is needed
       */
      let date = null;
      // let time = null;

      if (data.scheduled_at) {
        date = data.scheduled_at.substring(0, 10);
        // time = data.scheduled_at.substring(11, 16);
      }

      console.log({ data });

      this.form = {
        featuredVideo: {
          title: data.title,
          description: data.description,
          date
          // time
        }
      };
    },
    update(form) {
      dailyVideosRepository
        .update(this.itemId, form)
        .then(response => {
          console.log({ response }, "update");
          GLOBAL_EVENT_BUS.$emit(this.eventName);

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Updated.");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(form) {
      dailyVideosRepository
        .create(form)
        .then(response => {
          console.log({ response }, "create");
          GLOBAL_EVENT_BUS.$emit(this.eventName);

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submit() {
      let featuredVideoForm = this.$refs.featuredVideoForm;

      let valid = featuredVideoForm.validate();

      if (valid) {
        this.loading = true;

        let form = { ...featuredVideoForm.form };

        /**
         * Uncomment this if time picker is needed
         */
        // form.scheduled_at = `${form.date} ${form.time}:00`;

        // form.scheduled_at = `${form.date} 00:00:00`;

        form = Object.assign(form, {
            scheduled_at: moment(`${form.date} 00:00:00`).utc().format("YYYY-MM-DD HH:mm:ss")
        });

        let formData = this.setFormData(form);

        if (this.itemId) {
          await this.update(formData);
        } else {
          await this.create(formData);
        }

        this.resetForm();
      }
    },
    setFormData(form) {
      console.log({ form }, "setFormData");

      if (form.video_file || form.vertical_thumbnail) {
        let formData = new FormData();

        formData.append("title", form.title);
        formData.append("description", form.description);
        formData.append("scheduled_at", form.scheduled_at);
        formData.append("video_file", form.video_file);
        formData.append("vertical_thumbnail", form.vertical_thumbnail);

        if (this.itemId) {
          formData.append("_method", "PUT");
        }

        return formData;
      }

      if (this.itemId) {
        form._method = "PUT";
      }

      return form;
    },
    showDetailsEventBus() {
      GLOBAL_EVENT_BUS.$off("showDetails_featuredVideo");
      GLOBAL_EVENT_BUS.$on("showDetails_featuredVideo", data => {
        this.title = "Edit Scheduled Video";
        this.itemId = data.id;
        this.showDetails(data);
      });
    },
    setupEventBus() {
      this.showDetailsEventBus();
    },
    resetForm() {
      this.title = "Add Scheduled Video";
      this.componentKey++;
      this.form = {};
      this.itemId = null;

      GLOBAL_EVENT_BUS.$emit("resetSelected_featuredVideo");
    }
  },
  mounted() {
    this.setupEventBus();
  }
};
</script>
